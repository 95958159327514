import Vue from 'vue';
import Component from 'vue-class-component';

import searchIcon from 'assets/icons/search_icon_new.svg';
import SearchResultSlug from 'components/SearchResultSlug/SearchResultSlug.vue';

@Component({
  props: {
    isPredictive: {
      type: Boolean,
      default: false,
      description: 'Boolean if show empty query or predictive',
    },
    activitiesSearchResults: {
      required: false,
      description: 'Activities objects from search',
    },

    suggestionsResults: {
      required: true,
      description: 'Suggestions to display',
    },
    searchValue: {
      required: false,
      description: 'Actual predictive search text',
    },
    loading: {
      required: true,
      type: Boolean,
    },
    isSpain: {
      required: true,
      type: Boolean,
    },
    allSearchResults: {
      required: false,
      type: Array,
    },
  },
  components: {
    SearchResultSlug,
  },
})
export default class SearchOptionsComponent extends Vue {
  parseProductList(rawList) {
    return rawList.map(product => {
      const isBox = this.isBoxProduct(product);
      const isComboActivity = this.isComboActivityProduct(product);
      const isActivity = this.isPartnerActivityProduct(product);
      const baseProduct = this.createBaseProduct(product, isBox);

      if (isActivity) {
        this.adjustCategoryName(baseProduct);
      }
      if (isComboActivity) {
        this.adjustComboActivityTypeProduct(baseProduct, product);
      }
      return baseProduct;
    });
  }

  isBoxProduct(product) {
    return product?.child_type === 'Box';
  }
  isComboActivityProduct(product) {
    return product?.child_type === 'ComboActivity';
  }
  isPartnerActivityProduct(product) {
    return product?.child_type === 'PartnerActivity';
  }

  createBaseProduct(product, isBox) {
    return {
      id: product?.id,
      name: product?.name,
      child_type: product.child_type || 'Box',
      product_url: isBox
        ? product.box?.product_url
        : product.partneractivity?.product_url,
      category: isBox
        ? product.box?.category
        : product.partneractivity?.category,
      ...(isBox && {
        count_activities_available_and_visible:
          product.box.count_activities_available_and_visible,
      }),
    };
  }

  adjustCategoryName(baseProduct) {
    if (baseProduct.category?.name === 'Ocasiones') {
      baseProduct.category.name = baseProduct.name;
    }
  }

  adjustComboActivityTypeProduct(baseProduct, product) {
    baseProduct.product_url = product.comboactivity?.product_url;
    baseProduct.category = product.comboactivity?.category;
  }

  get parseProductListResult() {
    return this.parseProductList(this.allSearchResults);
  }
  searchIcon = searchIcon;
  searchText = this.isSpain
    ? 'Busca resultados de '
    : ' Ver todos los resultados de ';
}

import { computed } from 'vue';

const { storeToRefs } = require('pinia');
const { useFiltersStore } = require('stores/filters');

const useFilters = () => {
  const filterStore = useFiltersStore();
  const {
    getFacets,
    getCurrentFilters,
    getCurrentTags,
    getOptionCards,
    getCardSelected,
    getResults,
    getPageInfo,
    getLoading,
    getCurrentOrder,
    getAllResultsCount,
  } = storeToRefs(filterStore);

  const handleChangeFilters = async (key, type) => {
    await filterStore.handleChangeFilters(key, type);
  };

  const getDataFromFilters = async () => {
    const res = await filterStore.getDataFromFilters();
    return res;
  };

  const getTravel = async () => {
    const res = await filterStore.getTravel();
    return res;
  };

  const getBoxes = async () => {
    const res = await filterStore.getBoxes();
    return res;
  };

  const getActivities = async () => {
    const res = await filterStore.getActivities();
    return res;
  };

  const clearCurrentFilters = () => {
    filterStore.clearCurrentFilters();
  };

  const setCard = (cardId, fireSearch = true) => {
    filterStore.setCard(cardId, fireSearch);
  };
  const setBoxCategory = category => {
    filterStore.setBoxCategory(category);
  };

  const deleteTags = ({ key, inputType, value }) => {
    filterStore.deleteTags({ key, inputType, value });
  };

  const setKeywords = keywords => {
    filterStore.setKeywords(keywords);
  };
  const updatePageNumber = pageNumber => {
    filterStore.updatePageNumber(pageNumber);
  };

  const resetPaginationNumber = () => {
    filterStore.resetPaginationNumber();
  };

  return {
    handleChangeFilters,
    getDataFromFilters,
    getTravel,
    getBoxes,
    getActivities,
    clearCurrentFilters,
    setCard,
    setBoxCategory,
    setKeywords,
    updatePageNumber,
    deleteTags,
    resetPaginationNumber,
    getPageInfo: computed(() => getPageInfo.value),
    getFacets: computed(() => getFacets.value),
    getCurrentFilters: computed(() => getCurrentFilters.value),
    getCurrentTags: computed(() => getCurrentTags.value),
    getOptionCards: computed(() => getOptionCards.value),
    getCardSelected: computed(() => getCardSelected.value),
    getResults: computed(() => getResults.value),
    getLoading: computed(() => getLoading.value),
    getCurrentOrder: computed(() => getCurrentOrder.value),
    getAllResultsCount: computed(() => getAllResultsCount.value),
  };
};

export default useFilters;

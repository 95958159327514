export const INITIAL_STATE_BOX = {
  category_name: [],
  participants: [],
  price: [],
  province: [],
  cities: [],
};
export const INITIAL_STATE_ACTIVITIES = {
  price: [],
  has_online_reservation: false,
  category_name: [],
  subcategory_name: [],
  participants: [],
  province: [],
  cities: [],
};

export const INITIAL_STATE_TRAVEL_UY = {
  price: [],
  has_online_reservation: false,
  participants: [],
  province: [],
  cities: [],
};

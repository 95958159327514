var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!!_vm.slides.length),expression:"!!slides.length"}],staticClass:"relative isolate w-full bg-primary",style:({ zIndex: '999' })},[_c('div',{staticClass:"max-w-[360px] mx-auto grid justify-between text-text-primary overflow-clip items-center",style:({
      gridTemplateColumns: '1.5em 17.5em 1.5em',
    })},[_c('button',{staticClass:"inline-flex place-content-center bg-linear-to-r z-20 disabled:opacity-75 py-[0.75em] transition-all",class:{
        'opacity-100': !_setup.loading,
        'opacity-0': _setup.loading,
        'invisible pointer-events-none': _vm.slides.length <= 1,
      },style:({ background: 'linear-gradient(to right, #222, transparent)' }),attrs:{"disabled":_setup.transitioning},on:{"click":() => _setup.slideTo(-1)}},[_c('svg',{attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('g',{attrs:{"id":"Arrow / Chevron_Left_L"}},[_c('path',{attrs:{"id":"Vector","d":"M14 7L9 12L14 17","stroke":"currentColor","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}})])])]),_vm._v(" "),_c('div',{ref:"sliderContentEl",staticClass:"w-full relative text-[0.875em] size-full leading-none font-body font-semibold z-10 transition-all text-center",class:[
        _setup.contentClasses,
        _setup.shouldRenderDobleline ? 'h-[60px]' : 'h-[48px]',
      ]},[(!_setup.loading)?[(_setup.currentSlide.href)?_c('a',{staticClass:"inline-flex size-full justify-center items-center",attrs:{"href":_setup.currentSlide.href,"target":"_blank"}},[_vm._v("\n          "+_vm._s(_setup.currentSlide.content)+"\n        ")]):_c('p',{staticClass:"inline-flex size-full justify-center items-center"},[_vm._v("\n          "+_vm._s(_setup.currentSlide.content)+"\n        ")])]:_vm._e()],2),_vm._v(" "),_c('button',{staticClass:"inline-flex place-content-center bg-linear-to-r z-20 disabled:opacity-75 py-[0.75em] transition-all",class:{
        'opacity-100': !_setup.loading,
        'opacity-0': _setup.loading,
        'invisible pointer-events-none': _vm.slides.length <= 1,
      },style:({ background: 'linear-gradient(to left, #222, transparent)' }),attrs:{"disabled":_setup.transitioning},on:{"click":function($event){return _setup.slideTo(1)}}},[_c('svg',{staticClass:"transform -scale-x-100 z-20",attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('g',{attrs:{"id":"Arrow / Chevron_Left_L"}},[_c('path',{attrs:{"id":"Vector","d":"M14 7L9 12L14 17","stroke":"currentColor","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}})])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <router-link
    v-if="productItem && productItem.product_url"
    :to="productItem.product_url"
    :key="productItem.id"
    class="bb-full-width"
  >
    <div
      v-if="productItem.name && descriptionContent"
      class="productListPanel bb-full-width bb-px-3 bb-py-1"
    >
      <div class="svg-icon" data-svg-wrapper v-html="svgContent"></div>
      <div class="text-container">
        <div class="title">{{ productItem.name }}</div>
        <p class="subtitle">
          {{ descriptionContent }}
        </p>
      </div>
    </div>
  </router-link>
</template>

<script>
  import Vue from 'vue';
  import Component from 'vue-class-component';

  const svgPaths = {
    Gastronomía: require('assets/icons/SearchBarIcons/Outline_KnifeFork.svg'),
    Aventura: require('assets/icons/SearchBarIcons/Outline_Compass.svg'),
    'Estar Bien': require('assets/icons/SearchBarIcons/Outline_HarmonieHeart.svg'),
    Estadías: require('assets/icons/SearchBarIcons/Outline_Reservation.svg'),
    Mix: require('assets/icons/SearchBarIcons/Outline_Lightning.svg'),
    Originals: require('assets/icons/SearchBarIcons/Outline_Diamond.svg'),
    Entretenimiento: require('assets/icons/SearchBarIcons/Outline_Ticket.svg'),
    Viajes: require('assets/icons/SearchBarIcons/Outline_MapPin.svg'),
    Imperdibles: require('assets/icons/SearchBarIcons/Outline_Star.svg'),
    'En Argentina': require('assets/icons/SearchBarIcons/Outline_ArgentinaFlag.svg'),
    'Cursos y Talleres': require('assets/icons/SearchBarIcons/Outline_BookOpen.svg'),
    '¡Sorpresa!': require('assets/icons/SearchBarIcons/Outline_Balloon.svg'),
    '¡Feliz Cumple!': require('assets/icons/SearchBarIcons/Outline_Birthday.svg'),
    '¡Perdón!': require('assets/icons/SearchBarIcons/Outline_Flower.svg'),
    '¡Gracias!': require('assets/icons/SearchBarIcons/Outline_Smile.svg'),
    '¡Feliz Día!': require('assets/icons/SearchBarIcons/Outline_Sun.svg'),
    'Te Extraño': require('assets/icons/SearchBarIcons/Outline_HandHeart.svg'),
    '¡Feliz Aniversario!': require('assets/icons/SearchBarIcons/Outline_CalendarHeart.svg'),
    '¡Te Amo!': require('assets/icons/SearchBarIcons/Outline_Heart.svg'),
    Default: require('assets/icons/SearchBarIcons/Outline_Default.svg'),
  };

  @Component({
    props: {
      category: {
        type: String,
        required: true,
        default: '',
      },
      productItem: {
        type: Object,
        required: true,
        default: '',
      },
    },
  })
  export default class SearchResultSlug extends Vue {
    get svgContent() {
      if (
        [
          'Desayunos, Almuerzos y Tapeos',
          'Experiencias Gastronómicas',
          'Delivery y Take Away',
        ].includes(this.category)
      ) {
        return svgPaths['Gastronomía'];
      }
      return svgPaths[this.category] || svgPaths.Default;
    }

    get descriptionContent() {
      const descriptionMap = {
        Box: `Regalo con ${this.productItem.count_activities_available_and_visible} opciones`,
        PartnerActivity: `Actividad - ${this.category}${
          this.productItem.subcategory
            ? ` - ${this.productItem.subcategory}`
            : ''
        }`,
        ComboActivity: `Actividad - ${this.category}${
          this.productItem.subcategory
            ? ` - ${this.productItem.subcategory}`
            : ''
        }`,
      };
      return descriptionMap[this.productItem.child_type] || '';
    }
  }
</script>

<style lang="scss" scoped>
  @import '~assets/styles/index.scss';
  .bb-full-width {
    width: 100%;
  }
  .productListPanel {
    flex: 1;
    min-width: 163px;
    min-height: 37px;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    display: inline-flex;
    @media screen and (max-width: $screen-sm) {
      padding-left: 0;
    }
  }

  .text-container {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2px;
    display: inline-flex;
  }

  .title {
    color: #4d4d4d;
    font-size: 14px;
    font-family: Figtree;
    font-weight: 400;
    line-height: 18px;
    word-wrap: break-word;
  }

  .subtitle {
    color: #808080;
    font-size: 12px;
    font-family: Figtree;
    font-weight: 400;
    line-height: 17px;
    word-wrap: break-word;
  }
  .svg-icon {
    height: 20px;
    width: 20px;
  }
</style>

<template>
  <a
    v-if="show"
    class="socialAnchor bb-iconColor"
    rel="noopener noreferrer"
    :href="href"
    target="_blank"
    v-html="icon"
  >
  </a>
</template>

<script>
  export default {
    props: {
      ariaLabel: {
        type: String,
        required: true,
      },
      icon: {
        type: String,
        required: true,
      },
      href: {
        type: String,
        required: true,
      },
      show: {
        type: Boolean,
        default: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '~assets/styles/index.scss';
  .socialAnchor {
    cursor: pointer;
    height: 22px;
    width: 22px;
  }
</style>

<template>
  <div class="BaseFooter">
    <section class="footer bb-mb-6">
      <div class="footer-container container">
        <div
          class="full-width bb-row bb-align-items-center bb-justify-content-center"
        >
          <template v-for="(point, i) in topLinks">
            <div
              class="contactStep bb-fcenter"
              v-if="(!isMobile || contactStepIndex === i) && point.show"
            >
              <div
                class="innerContactStep bb-row bb-align-items-center"
                @click="redirectToTopLink(point)"
              >
                <span
                  class="More-icon fix-icons-bug"
                  v-html="point.icon"
                ></span>
                <span class="contactStepText bb-ml-1">
                  <span class="bb-bold text">{{ point.title }}</span>
                </span>
              </div>
            </div>
            <span
              v-if="!isMobile && i !== topLinks.length - 1 && point.show"
              class="More-icon"
              v-html="require('assets/icons/Line.svg')"
            ></span>
          </template>
        </div>
        <div
          v-if="isMobile"
          class="stepsController bb-row bb-justify-content-center"
        >
          <div class="bb-cursor-pointer" v-for="(item, index) in topLinks">
            <span
              class="Oval-icon bb-mx-1"
              :class="{ '--primary': contactStepIndex === index }"
              v-html="require('common/icons/Oval.svg')"
              v-if="item.show"
              @click="changeStep(index)"
            ></span>
          </div>
        </div>
      </div>
      <div class="separator"></div>
    </section>
    <div class="container content containerFooter">
      <div class="companyDescription">
        <p class="companySlogans figtree-font bb-semi-bold">
          Regalamos porque <br />
          ver felices a los nuestros <br />
          <span class="figtree-font bb-text-primary"
            >nos hace muy felices.</span
          >
        </p>
      </div>
      <div class="navRow hidden-xs">
        <section>
          <h1 class="listHeading figtree-font bb-semi-bold">Regalos</h1>
          <ul class="navList" v-if="categories.length > 0">
            <li v-for="category in categories" :key="category.id">
              <router-link
                :to="{ name: 'products', query: { category: [category.slug] } }"
              >
                {{ category.name }}
              </router-link>
            </li>
          </ul>
        </section>
        <section>
          <h1 class="listHeading figtree-font bb-semi-bold">Ayuda</h1>
          <ul class="navList">
            <li>
              <a :href="contactUrl" target="_blank"> Contacto </a>
            </li>
            <li>
              <a :href="helpUrl" target="_blank"> Preguntas frecuentes </a>
            </li>
            <li v-if="$store.tld === 'pe'">
              <router-link :to="{ name: 'claim' }">
                Libro de Reclamaciones
              </router-link>
            </li>
          </ul>
        </section>
        <section>
          <h1 class="listHeading figtree-font bb-semi-bold">Bigbox</h1>
          <ul class="navList">
            <li>
              <router-link :to="{ name: 'aboutBigbox' }">
                ¿Qué es Bigbox?
              </router-link>
            </li>
            <li v-if="$siteConfig.config.selling_spot_enabled">
              <router-link :to="{ name: 'sellingSpots' }">
                Puntos de venta
              </router-link>
            </li>
            <li @click="redirectToCorpo()">
              <span v-if="$store.tld === 'pe'">Ventas corporativas</span>
              <span v-else>Soluciones corporativas</span>
            </li>
            <li>
              <a href="https://people.bigbox.com.ar/" target="_blank">
                {{ $translate('trabajá') | capitalize }} con nosotros
              </a>
            </li>
            <li>
              <router-link :to="{ name: 'partnerContact' }">
                <span v-if="$store.tld === 'es'">Conviértete en prestador</span>
                <span v-else
                  >{{ $translate('convertite') | capitalize }} en partner</span
                >
              </router-link>
            </li>
          </ul>
        </section>
      </div>
    </div>
    <div class="container content containerFooter --lower">
      <div class="lowerContentTop"></div>
      <span class="bb-select countriesWrapper">
        <select
          aria-label="Countries"
          class="countries"
          v-model="currentCountryHost"
          @change="countryChanged"
        >
          <option
            v-for="country in countries"
            :key="country.host"
            :value="country.host"
          >
            {{ country.name }}
          </option>
        </select>
      </span>
      <div class="rightLinks bb-fcenter-y">
        <div class="termsList">
          <router-link :to="{ name: 'policiesAndGuarantees' }">
            Políticas y Garantías
          </router-link>
          <router-link :to="{ name: 'termsAndConditions' }">
            Términos y condiciones
          </router-link>
          <router-link
            v-if="$store.tld === 'ar'"
            :to="{ name: 'consumerDefense' }"
          >
            Defensa al consumidor
          </router-link>
        </div>
        <div class="buttonsList bb-bold bb-row-to-column">
          <button
            class="return-purchase-button bb-button --primary bb-pr-1 bb-row bb-align-items-center"
            v-if="['ar', 'cl'].includes($store.tld)"
            @click="openReturnGift"
          >
            <span
              class="return-purchase-icon bb-fcenter"
              v-html="icons.returnPurchaseIcon"
            ></span>
            <span>Devolver mi compra</span>
          </button>

          <div class="buttonsDivisor bb-hidden-sm-max bb-mx-6"></div>
          <button
            type="button"
            class="bb-button --white --home --withBorder bb-px-5"
            onclick="location.href='/partners'"
          >
            Partners
          </button>
        </div>
        <div class="socialList bb-sm-max-mb-2">
          <SocialLink
            v-for="(social, index) in socialLinks"
            :key="index"
            :aria-label="social['aria-label']"
            :icon="social.icon"
            :href="social.url"
            :show="social.show"
          >
          </SocialLink>
        </div>
        <div v-if="currentCountry === 'ar'" class="afip-logo bb-ml-2">
          <a
            href="http://qr.afip.gob.ar/?qr=g5IORVaFmVIsMbCsTMMwPw,,"
            target="_F960AFIPInfo"
            ><img src="https://www.afip.gob.ar/images/f960/DATAWEB.jpg"
          /></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import storeIcon from 'assets/icons/store_white.svg';
  import questionIcon from 'common/icons/Question.svg';
  import corporateIcon from 'common/icons/Corporate.svg';
  import returnPurchaseIcon from 'assets/icons/return-purchase.svg';
  import getLastTld from 'common/services/utils/getLastTld';
  import { getHelpUrl } from 'common/utils/getHelpUrl';
  import { getCorpoUrl } from 'common/utils/getCorpoUrl';
  import { getContactUrl } from 'common/utils/getContactUrl';
  import SocialLink from 'components/SocialLink/SocialLink.vue';
  import instagramIcon from 'common/icons/instagram.svg';
  import facebookIcon from 'assets/icons/facebook.svg';
  import xIcon from 'common/icons/X.svg';

  @Component({
    components: { SocialLink },
    props: {
      categories: {
        type: Array,
        default: () => [],
      },
    },
  })
  export default class BaseFooterComponent extends Vue {
    currentCountryHost = null;
    windowWidth = null;
    contactStepIndex = 0;
    swipePositionStart = null;
    swipePositionEnd = null;
    maxMobileResolution = 768;
    icons = {
      returnPurchaseIcon,
    };
    currentCountry = getLastTld();

    beforeMount() {
      this.recalculateWidth();
    }

    get topLinks() {
      const links = [
        {
          id: 1,
          title: `¿${this.$translate('Necesitás')} ayuda? ${this.$translate(
            'Escribinos',
          )}`,
          icon: questionIcon,
          route: this.helpUrl,
          externalRoute: true,
          show: true,
        },
        {
          id: 2,
          title:
            getLastTld(window.location.host) !== 'mx'
              ? 'Venta Corporativa'
              : 'Venta Empresarial',
          icon: corporateIcon,
          route: getCorpoUrl(),
          externalRoute: true,
          show: true,
        },
      ];
      if (this.$siteConfig.config.selling_spot_enabled) {
        links.unshift({
          id: 0,
          title: 'Puntos de venta',
          icon: storeIcon,
          route: 'sellingSpots',
          externalRoute: false,
          show: true,
        });
      }
      return links;
    }

    redirectToTopLink(point) {
      if (point.externalRoute) {
        window.location.href = point.route;
      } else {
        this.$router.push({ name: point.route });
      }
    }

    redirectToCorpo() {
      window.location.href = getCorpoUrl();
    }

    created() {
      this.currentCountryHost = this.$store.host;
      window.addEventListener('resize', this.recalculateWidth);
    }

    beforeDestroy() {
      window.removeEventListener('resize', this.recalculateWidth);
    }

    recalculateWidth() {
      this.windowWidth = window.innerWidth;
    }

    get isMobile() {
      return this.windowWidth < this.maxMobileResolution;
    }

    changeStep(index) {
      this.contactStepIndex = index;
    }

    handleStart(e) {
      e.preventDefault();
      this.swipePositionStart = e.changedTouches[0].screenX;
    }

    handleEnd(e) {
      e.preventDefault();
      if (this.swipePositionStart > e.changedTouches[0].screenX) {
        this.nextStep();
      } else {
        this.prevStep();
      }
    }

    prevStep() {
      this.contactStepIndex =
        this.contactStepIndex !== 0 ? this.contactStepIndex - 1 : 2;
    }

    nextStep() {
      this.contactStepIndex =
        this.contactStepIndex !== 2 ? this.contactStepIndex + 1 : 0;
    }

    openReturnGift() {
      this.$router.push({
        name: 'contact',
        params: { selectedSubject: 'Devolver mi compra' },
      });
    }

    get countries() {
      return [
        { name: 'Argentina', host: this.replaceHostTld('com.ar') },
        { name: 'España', host: this.replaceHostTld('es') },
        { name: 'Perú', host: this.replaceHostTld('com.pe') },
        { name: 'Chile', host: this.replaceHostTld('cl') },
        { name: 'Uruguay', host: this.replaceHostTld('com.uy') },
        { name: 'México', host: this.replaceHostTld('com.mx') },
      ];
    }

    get instagramUrl() {
      const { tld } = this.$store;
      return `https://www.instagram.com/bigbox_${tld}/`;
    }

    get helpUrl() {
      return getHelpUrl();
    }

    get contactUrl() {
      return getContactUrl();
    }

    get facebookUrl() {
      const { tld } = this.$store;
      switch (tld) {
        case 'mx':
          return 'https://www.facebook.com/bigbox.mex/';
        case 'es':
          return `https://www.facebook.com/bigbox.esp/`;
        default:
          return `https://www.facebook.com/bigbox.${tld}/`;
      }
    }

    replaceHostTld(tld) {
      const { host } = this.$store;
      return host.replace(
        /^(www\.[^.]+\.)[^:]+(:\d+)?$/,
        (fullMatch, preHost, postHost) => {
          return preHost + tld + (postHost || '');
        },
      );
    }

    countryChanged() {
      window.location.host = this.currentCountryHost;
    }

    get xUrl() {
      return 'https://x.com/bigboxar?';
    }

    get socialLinks() {
      return [
        {
          'aria-label': 'Instagram',
          icon: instagramIcon,
          url: this.instagramUrl,
          show: true,
        },
        {
          'aria-label': 'Facebook',
          icon: facebookIcon,
          url: this.facebookUrl,
          show: true,
        },
        {
          'aria-label': 'X',
          icon: xIcon,
          url: this.xUrl,
          show: this.currentCountry === 'ar',
        },
      ];
    }
  }
</script>

<style lang="scss" scoped>
  @import '~assets/styles/index.scss';

  $almost-red: #ea6e54;

  .BaseFooter {
    position: relative;
    border-top: 1px solid $LIGHT-GRAY;
    padding-bottom: 100px;
    background-color: rgba(234, 234, 234, 0.2);
  }

  .containerFooter {
    width: 100%;
    max-width: 1300px;
  }

  .bigboxLogo {
    height: 100px;
    margin-left: 0px;
    margin-right: auto;
  }

  .qrIcon {
    background-color: #c6c6c6;
    height: 36px;
    width: 26px;
    display: inline-block;
    margin-right: 0;
    margin-left: auto;
  }

  .companyDescription {
    width: 100%;
    margin-left: 0px;
    margin-right: auto;
    color: $DARK-GRAY;
    font-size: 12px;
    line-height: 1.2;
    margin-bottom: 40px;

    p {
      margin-top: 16px;

      &:first-child {
        margin-top: 0;
      }
    }

    strong {
      font-weight: 600;
    }

    @media (max-width: $screen-xs-max) {
      margin-bottom: 30px;
    }
  }

  .content {
    display: flex;

    @media (max-width: $screen-xs-max) {
      max-width: 375px;
    }

    &.--logos {
      align-items: flex-end;
    }

    &.--lower {
      flex-flow: row wrap;
      align-items: center;
    }
  }

  .navRow {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-right: 0;
    margin-left: auto;
  }

  .navList {
    margin: auto;
    padding: 0;
    list-style: none;

    > li {
      margin-top: 9px;
      margin-bottom: 9px;

      > a,
      span {
        color: $GRAY-LIGHT-2;
        text-decoration: none;
        font-size: 13px;
        line-height: 16px;
        font-weight: bold;
        cursor: pointer;

        &:hover {
          color: $almost-red;
        }
      }
    }
  }

  .listHeading {
    color: $DARK-GRAY;
    font-size: 18px;
    margin-top: 0;
    margin-bottom: $XSMALL;
  }

  .companySlogans {
    font-size: 20px;

    &:last-child {
      margin-top: 20px;
    }
  }

  .lowerContentTop,
  .separator {
    width: 100%;

    @media (min-width: $screen-sm-min) {
      border-top: 1px solid #e2e2e2;
      padding-top: 15px;
      margin-top: 20px;
    }
  }

  .separator {
    padding: 0;
    margin: 0;
  }

  .countriesWrapper {
    width: 150px;

    @media (max-width: $screen-xs-max) {
      display: block;
      width: 100%;
    }
  }

  .countries {
    width: 100%;
    font-weight: 500;

    @media (min-width: $screen-sm-min) {
      max-width: 215px;
      font-size: 11px !important;
    }

    @media (max-width: $screen-xs-max) {
      display: block;
      margin: 26px auto 30px;
      border-radius: 6px !important;
      padding-left: 18px !important;
      color: black;
    }
  }

  .socialList {
    display: inline-block;
    color: $DARK-GRAY;
    font-size: 22px;
    height: 22px;
    vertical-align: middle;

    /* stylelint-disable-next-line no-descending-specificity */
    > a {
      display: inline-block;

      @media (min-width: $screen-sm-min) {
        margin-left: $XXSMALL;
      }

      @media (max-width: $screen-xs-max) {
        margin-right: $XXSMALL;
      }
    }

    @media (min-width: $screen-sm-min) {
      margin-left: $MINI;
    }
  }

  .rightLinks {
    margin-right: 0px;
    margin-left: auto;

    @media (max-width: $screen-sm-max) {
      width: 100%;
      margin-top: 15px;
      margin-left: -15px;
    }

    @media (max-width: $screen-xs-max) {
      margin-top: 0px;
      margin-left: 0px;
      display: block;
    }

    .termsList {
      color: #aaa;
      font-size: 12px;
      font-weight: bold;
      vertical-align: middle;

      /* stylelint-disable-next-line no-descending-specificity */
      > a {
        display: block;

        @media (min-width: $screen-sm-min) {
          display: inline-block;
          margin-left: 50px;
        }

        @media (max-width: $screen-md-min) {
          margin-left: $XXSMALL;
        }

        @media (max-width: $screen-xs-max) {
          margin: $SMALL 0;
        }
      }

      @media (min-width: $screen-sm-min) {
        display: inline-block;
      }

      @media (max-width: $screen-xs-max) {
        margin: 26px 0;
        font-size: 13px;
      }
    }

    .buttonsList {
      margin-left: 50px;

      @media (max-width: $screen-md-min) {
        margin-left: $XXSMALL;
      }

      @media (max-width: $screen-sm-min) {
        margin-left: 0;
        margin-bottom: $SMALL;
        align-items: flex-start;
      }

      button {
        height: 32px;
        padding-top: 0;
        padding-bottom: 0;

        &:first-of-type {
          @media (max-width: $screen-md-min) {
            margin-right: $XXSMALL;
          }

          @media (max-width: $screen-sm-min) {
            margin-right: 0;
            margin-bottom: $SMALL;
          }
        }

        &.returnGift {
          display: flex;
          padding-left: 0;

          .ReturnGif-icon {
            height: 23px;
            margin: 0 3px;
          }
        }
      }

      > .buttonsDivisor {
        width: 1px;
        height: 24px;
        background: #b5b5b5;
        align-self: center;
      }

      @media (min-width: $screen-sm-min) {
        display: inline-flex;
      }
    }
  }

  .footer-container {
    display: flex;
    padding: 0;
    width: 100%;
    max-width: 1300px;

    @media (max-width: $screen-sm-min) {
      flex-direction: column;
      padding: $BASE 0;
      margin-top: $BASE;
    }
  }

  .contactStep {
    width: 33.3%;
    padding: $SMALL;

    @media (max-width: $screen-sm-min) {
      width: 100%;
      padding: 0 $SMALL;
    }
  }

  .innerContactStep {
    width: 100%;
    max-width: 395px;
    margin: 0 auto;
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $MID-GRAY;

    @media (max-width: $screen-sm-min) {
      height: 35px;
    }
  }

  .contactStepText {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .contactStepText-title {
    display: inline-block;
    width: 100%;
  }

  .More-icon ::v-deep svg {
    height: 20px;
    margin-bottom: -4px;
    color: $DARK-GRAY;
  }

  .Oval-icon {
    height: 10px;
    color: #dbdbdb;

    &.--primary {
      ::v-deep svg {
        color: $PRIMARY;
      }
    }
  }

  .columnLinks {
    @media (max-width: $screen-sm-min) {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  .text {
    color: $DARK-GRAY;
    font-size: 16px;
    margin-top: 0;
  }

  .return-purchase-button {
    display: flex;

    .return-purchase-icon {
      height: 31px;
      width: 31px;
    }
  }

  .afip-logo {
    width: 50px;
    height: auto;
  }
</style>

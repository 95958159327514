<script setup>
  import { computed, onBeforeMount, onMounted, ref, watch } from 'vue';

  const props = defineProps({
    slides: {
      type: Array,
      default: () => [
        /**
         * @typedef PromobarSlide {
         *   content: string;
         *   href: string;
         * }
         */
      ],
    },
  });

  // == Settings

  const SLIDE_DURATION = 5000; // ms to wait before change to next slide automatically
  const TRANSITION_DURATION = 250; // slide animation duration. Must be changed in css also.

  // == Slide automatically after 3s

  const enableAutoSlide = computed(() => {
    return props.slides.length > 1;
  });

  const intervalId = ref(0);
  const initAutoSlide = () => {
    if (!enableAutoSlide.value) {
      return;
    }

    intervalId.value = setInterval(() => {
      slideTo(1);
    }, SLIDE_DURATION);
  };
  onMounted(() => {
    initAutoSlide();
  });

  // == Check if any slide must be rendered in two lines

  const sliderContentEl = ref();

  /**
   * Waits until the element bounding rect is loaded, and then returns it.
   * @param el {HTMLElement} element
   * @param max {number} max retries
   */
  const getElementRect = async (el, max = 5) => {
    const { promise, resolve, reject } = Promise.withResolvers();
    let tries = 0;
    const intervalId = setInterval(() => {
      tries += 1;
      if (tries >= max) {
        clearInterval(intervalId);
        return reject();
      }
      const rect = el.getBoundingClientRect();
      if (rect?.x || rect?.y) {
        clearInterval(intervalId);
        return resolve(rect);
      }
    }, 500);
    return promise;
  };

  const loading = ref(true);
  const shouldRenderDobleline = ref(false);
  watch(
    () => [sliderContentEl.value, props.slides],
    async ([sliderEl, slides]) => {
      if (!props.slides.length) {
        return;
      }

      loading.value = true;

      if (!sliderEl) {
        return;
      }

      /**
       * To know if the slide will have a line break, we must render all of the slides
       * and check if some element has a width greater than 280px.
       * We use 280px because that's the definition from Figma and design.
       */
      const vEl = document.createElement('span');
      vEl.classList.add('overflow-visible', 'whitespace-nowrap', 'opacity-0');
      sliderEl.append(vEl);

      shouldRenderDobleline.value = false;
      for await (const s of slides) {
        vEl.textContent = s.content;
        const r = await getElementRect(vEl);
        if (r.width > 280) {
          shouldRenderDobleline.value = true;
          break;
        }
      }

      vEl.remove();
      loading.value = false;
    },
    { immediate: true },
  );

  // == Transition and sliding management

  const currentIndex = ref(0);
  const transitionId = ref(0);
  const transitioning = ref(false);
  const contentClasses = ref('');

  /**
   * Slides to next or prev slide.
   * @param d {number} must be 1 (next) or -1 (prev)
   */
  function slideTo(d) {
    // Stop automatic interval

    clearInterval(intervalId.value);
    if (transitioning.value) {
      return;
    }

    // Fix index overflow

    let nextIndex = currentIndex.value + d;
    if (nextIndex < 0) {
      nextIndex = props.slides.length - 1;
    } else if (nextIndex >= props.slides.length) {
      nextIndex = 0;
    }
    transitioning.value = true;

    // Perform transitions
    // 1. move the element outside the parent element, which has overflow-hidden. (hide the element)
    // 2. change the slide content updating the index.
    // 3. move the element back to the center.

    const transitionClasses =
      d < 0
        ? ['slide-out-left', 'slide-in-right']
        : ['slide-out-right', 'slide-in-left'];

    contentClasses.value = transitionClasses[0];
    transitionId.value = setTimeout(() => {
      contentClasses.value = transitionClasses[1];
      currentIndex.value = nextIndex;
      transitionId.value = setTimeout(() => {
        contentClasses.value = '';
        transitioning.value = false;
        initAutoSlide();
      }, TRANSITION_DURATION);
    }, TRANSITION_DURATION);
  }

  const currentSlide = computed(() => props.slides.at(currentIndex.value));
</script>

<template>
  <div
    class="relative isolate w-full bg-primary"
    :style="{ zIndex: '999' }"
    v-show="!!slides.length"
  >
    <div
      class="max-w-[360px] mx-auto grid justify-between text-text-primary overflow-clip items-center"
      :style="{
        gridTemplateColumns: '1.5em 17.5em 1.5em',
      }"
    >
      <button
        @click="() => slideTo(-1)"
        class="inline-flex place-content-center bg-linear-to-r z-20 disabled:opacity-75 py-[0.75em] transition-all"
        :class="{
          'opacity-100': !loading,
          'opacity-0': loading,
          'invisible pointer-events-none': slides.length <= 1,
        }"
        :style="{ background: 'linear-gradient(to right, #222, transparent)' }"
        :disabled="transitioning"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Arrow / Chevron_Left_L">
            <path
              id="Vector"
              d="M14 7L9 12L14 17"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </svg>
      </button>
      <div
        class="w-full relative text-[0.875em] size-full leading-none font-body font-semibold z-10 transition-all text-center"
        :class="[
          contentClasses,
          shouldRenderDobleline ? 'h-[60px]' : 'h-[48px]',
        ]"
        ref="sliderContentEl"
      >
        <template v-if="!loading">
          <a
            class="inline-flex size-full justify-center items-center"
            v-if="currentSlide.href"
            :href="currentSlide.href"
            target="_blank"
          >
            {{ currentSlide.content }}
          </a>
          <p class="inline-flex size-full justify-center items-center" v-else>
            {{ currentSlide.content }}
          </p>
        </template>
      </div>
      <button
        class="inline-flex place-content-center bg-linear-to-r z-20 disabled:opacity-75 py-[0.75em] transition-all"
        :class="{
          'opacity-100': !loading,
          'opacity-0': loading,
          'invisible pointer-events-none': slides.length <= 1,
        }"
        :style="{ background: 'linear-gradient(to left, #222, transparent)' }"
        :disabled="transitioning"
        @click="slideTo(1)"
      >
        <svg
          class="transform -scale-x-100 z-20"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Arrow / Chevron_Left_L">
            <path
              id="Vector"
              d="M14 7L9 12L14 17"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </svg>
      </button>
    </div>
  </div>
</template>

<style scoped>
  .slide-out-right {
    animation: slide-out-left-anim 250ms ease-in-out;
    animation-fill-mode: forwards;
  }
  .slide-out-left {
    animation: slide-out-right-anim 250ms ease-in-out;
    animation-fill-mode: forwards;
  }

  .slide-in-right {
    animation: slide-in-left-anim 250ms ease-in-out;
    animation-fill-mode: forwards;
  }
  .slide-in-left {
    animation: slide-in-right-anim 250ms ease-in-out;
    animation-fill-mode: forwards;
  }

  /** Center to left */
  @keyframes slide-out-left-anim {
    0% {
      opacity: 1;
      right: 0;
    }

    80% {
      right: 125%;
      opacity: 1;
    }
    100% {
      right: 125%;
      opacity: 0;
    }
  }

  /** Center to right */
  @keyframes slide-out-right-anim {
    0% {
      opacity: 1;
      left: 0;
    }

    80% {
      left: 125%;
      opacity: 1;
    }
    100% {
      left: 125%;
      opacity: 0;
    }
  }

  /** Right to center */
  @keyframes slide-in-right-anim {
    0% {
      left: 125%;
      opacity: 0;
    }
    20% {
      left: 125%;
      opacity: 1;
    }

    100% {
      left: 0;
      opacity: 1;
    }
  }

  /** Left to center */
  @keyframes slide-in-left-anim {
    0% {
      right: 125%;
      opacity: 0;
    }
    20% {
      right: 125%;
      opacity: 1;
    }

    100% {
      right: 0;
      opacity: 1;
    }
  }
</style>
